import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionSubmissionRequirements = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5">
                <div className="text-center">
                    <h3 className="fw-bolder mb-4">Submission Requirements</h3>
                    <p className="text-muted">Each team can contain 1 to 5 individual participants. The submission requirement as below:</p>
                </div>

                <div className="row">
                    <div className="col-md-6 col-lg-4 p-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex bg-warning text-white p-3 justify-content-between">
                                    <h5 className="fw-bold my-auto">PPT is REQUIRED</h5>
                                    <FontAwesomeIcon size="2x" icon={faCheckSquare} />
                                </div>
                                <div className="p-3">
                                    <p className="text-muted small mt-4">PPT for sharing your project idea and design, explain how you use PolarDB in your project and present a demo of your project. Please check the PolarDB Global Hackathon template slides for the submission requirement in detail.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 p-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex bg-warning text-white p-3 justify-content-between">
                                    <h5 className="fw-bold my-auto">Presentation Video</h5>
                                    <FontAwesomeIcon size="2x" icon={faCheckSquare} />
                                </div>
                                <div className="p-3">
                                    <p className="text-muted small mt-4">Video is optional but highly recommended for judges to have better understand about your project.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-4 p-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="d-flex bg-warning text-white p-3 justify-content-between">
                                    <h5 className="fw-bold my-auto">Zip File</h5>
                                    <FontAwesomeIcon size="2x" icon={faCheckSquare} />
                                </div>
                                <div className="p-3">
                                    <p className="text-muted small mt-4">Zip file containing your source code and your sample application is highly recommended. Please also provide the README for judges to run the application.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionSubmissionRequirements;
