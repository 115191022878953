import { Helmet } from 'react-helmet-async';
import Layout from '../../components/global/Layout/Layout';
import Hero from '../../components/home/Hero/Hero';
import SectionOverview from '../../components/home/SectionOverview/SectionOverview';
import SectionAwards from '../../components/home/SectionAwards/SectionAwards';
import SectionSchedule from '../../components/home/SectionSchedule/SectionSchedule';
import SectionContestTheme from '../../components/home/SectionContestTheme/SectionContestTheme';
import SectionSubmissionRequirements from '../../components/home/SectionSubmissionRequirements/SectionSubmissionRequirements';
import SectionJudgingCriteria from '../../components/home/SectionJudgingCriteria/SectionJudgingCriteria';
import SectionCTA from '../../components/home/SectionCTA/SectionCTA';

const Home = () => {
    return (
        <>
            <Helmet>
                <title>polarDB - PolarDB Hackathon</title>
            </Helmet>
            <Layout>
                <Hero />
                <SectionOverview />
                <SectionAwards />
                <SectionSchedule />
                <SectionContestTheme />
                <SectionSubmissionRequirements />
                <SectionJudgingCriteria />
                <SectionCTA />
            </Layout>
        </>
    );
};

export default Home;
