const SectionSchedule = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5 text-center">
                <h3 className="fw-bolder mb-3">Schedule</h3>
                <p className="text-muted">Follow these important dates, and get higher chance to win</p>

                <div className="my-5">
                    <img className="img-fluid d-none d-lg-block px-5" src="/assets/img/img-schedule-1.png" alt="Schedule Dekstop" />
                    <img className="img-fluid d-lg-none mx-auto px-5" src="/assets/img/img-schedule-2.png" alt="Schedule Mobile" />
                </div>

                <button type="button" className="btn btn-secondary disabled" disabled>Closed</button>
            </div>
        </section>
    );
};

export default SectionSchedule;
