import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionCTA = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5">
                <div className="card border-0 bg-warning text-white" style={{ borderRadius: '15px' }}>
                    <div className="card-body text-center p-5">
                        <p className="title display-5 fw-bolder">Are you ready? <br /> let's register and win the grand prize right now!</p>

                        <div className="d-flex gap-2 justify-content-center mt-5">
                            <button type="button" className="btn btn-light text-warning disabled" disabled>Closed</button>
                            <a className="btn btn-outline-light" href="https://chat.whatsapp.com/LJKPGuzxPvPHgVBQSHZI8E" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-1" icon={faWhatsapp} />
                                Any Question?
                            </a>
                        </div>
                    </div>
                </div>

                <div className="my-5 text-center">
                    <p className="h3 fw-bolder mb-5">Organizers & Partners</p>

                    <p className="text-muted">Organizers</p>
                    <img className="img-fluid w-50 mb-5" height="25px" width="auto" src="/assets/img/logo-alibaba-tianchi.png" alt="Alibaba Tianchi" />

                    <p className="text-muted">Partners</p>
                </div>
            </div>
        </section>
    );
};

export default SectionCTA;
