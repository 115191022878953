import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Hero = () => {
    return (
        <section className="bg-dark text-white pt-5" style={{ backgroundImage: `url('/assets/img/bg-hero.jpg')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center bottom' }}>
            <div className="container py-5 px-4 px-lg-5">
                <div className="row justify-content-between">
                    <div className="col-lg-8 my-auto">
                        <img className="img-fluid w-50" height="25px" width="auto" src="/assets/img/logo-alibaba-tianchi.png" alt="Alibaba Tianchi" />
                        <h1 className="title fw-bold display-5 my-3">PolarDB Global Hackathon 2023</h1>
                        <p className="lh-lg mb-4">Explore PolarDB in The Limitless, open-theme inovation challenge.</p>
                        <p className="fw-bolder mb-1">Total Grand Prize:</p>
                        <p className="fw-bolder text-warning display-6">$30.000</p>
                        <div className="d-grid gap-2 d-xl-flex  my-4">
                            <button type="button" className="btn btn-secondary rounded-1 px-3 py-2 disabled" disabled>
                                Join Now
                            </button>
                            <a className="btn btn-outline-light rounded-1 px-3 py-2" href="https://chat.whatsapp.com/LJKPGuzxPvPHgVBQSHZI8E" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className="me-2" icon={faWhatsapp} />
                                Any Question?
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
