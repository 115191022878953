const SectionAwards = () => {
    const images = [
        '/assets/img/awards/award-1.png',
        '/assets/img/awards/award-2.png',
        '/assets/img/awards/award-3.png',
        '/assets/img/awards/award-4.png',
        '/assets/img/awards/award-5.png',
        '/assets/img/awards/award-6.png',
    ];

    return (
        <section className="bg-dark text-white" style={{ backgroundImage: `url('/assets/img/bg-awards.jpg')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container p-4 p-lg-5">
                <div className="row">
                    <div className="col-lg-5">
                        <h3 className="fw-bolder">Awards</h3>
                        <p>Be The Champions to win the rewards</p>
                        <img className="img-fluid d-block mx-auto" src="/assets/img/img-awards.png" alt="Awards" />
                    </div>
                    <div className="col-lg-7">
                        <div className="row">
                            {images.map((image, index) => {
                                return (
                                    <div className="col-md-6 p-3" key={index}>
                                        <img className="img-fluid" src={image} alt={`Award-${index}`} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionAwards;
