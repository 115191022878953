import { faRobot, faTrophy, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SectionOverview = () => {
    return (
        <section>
            <div className="container p-4 p-lg-5 text-center">
                <h2 className="h3 fw-bolder mb-5">Hackathon Overview</h2>
                <p className="text-muted">PolarDB, the new-generation cloud-native relational database service developed by Alibaba Cloud, is fully compatible with MySQL, PostgreSQL and highly compatible with Oracle. PolarDB is developed by Alibaba Cloud, it provides high performance and high availability of commercial database with ease to use. This contest inspires developers and enterprises to use PolarDB to empower innovation.</p>

                <div className="row justify-content-center my-5">
                    <div className="col-md-6 col-lg-4 p-5">
                        <FontAwesomeIcon className="text-warning" size="3x" icon={faRobot} />
                        <p className="text-muted mt-4">Open to Global Developers and Enterprises</p>
                    </div>
                    <div className="col-md-6 col-lg-4 p-5">
                        <FontAwesomeIcon className="text-warning" size="3x" icon={faFolderOpen} />
                        <p className="text-muted mt-4">Leverage PolarDB to Build Innovative Projects</p>
                    </div>
                    <div className="col-md-6 col-lg-4 p-5">
                        <FontAwesomeIcon className="text-warning" size="3x" icon={faTrophy} />
                        <p className="text-muted mt-4">$30,000 USD Cash Prizes for Winners</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionOverview;
