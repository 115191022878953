const SectionJudgingCriteria = () => {
    return (
        <section style={{ background: '#FFF8F3' }}>
            <div className="container p-4 p-lg-5">
                <h3 className="fw-bolder text-center">Judging Criteria</h3>
            </div>
        </section>
    );
};

export default SectionJudgingCriteria;
