const SectionContestTheme = () => {
    return (
        <section className="bg-dark text-white" style={{ backgroundImage: `url('/assets/img/bg-awards.jpg')`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
            <div className="container p-4 p-lg-5">
                <div className="text-center">
                    <h3 className="fw-bolder mb-5">Contest Theme</h3>
                    <p>PolarDB Global Hackathon is an open theme Hackathon that welcomes global developers to join. Participants need to use PolarDB for MySQL or PolarDB for PostgreSQL to build functioning apps, websites, programs, tools or data dashboards of innovative value in any field or industry. Participants are required to use PolarDB for MySQL or PolarDB for PostgreSQL to build their projects.</p>
                </div>

                <div className="row my-3">
                    <div className="col-md-6 col-lg-3 p-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-text text-dark fw-bold text-center small my-4">Sustainable Development</h5>
                                <ul className="text-muted small">
                                    <li>green energy applications for home energy saving or home solar energy back to the grid</li>
                                    <li>smart agriculture systems for saving resources and increasing quantity and quality of food</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 p-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-text text-dark fw-bold text-center small my-4">Gaming & Web3</h5>
                                <ul className="text-muted small">
                                    <li>Metaverse (PolarDB for PostgreSQL supports the spatial-temporal engine called Ganos to build a digital twin world)</li>
                                    <li>Location-based sports game（PolarDB for PostgreSQL supports the spatial-temporal engine called Ganos to build a digital twin world)</li>
                                    <li>Build a global-server game (PolarDB for MySQL supports Global Database Network(GDN))</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 p-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-text text-dark fw-bold text-center small my-4">FinTech</h5>
                                <ul className="text-muted small">
                                    <li>Online banking, risk management, financial services, trading system</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 p-2">
                        <div className="card h-100">
                            <div className="card-body">
                                <h5 className="card-text text-dark fw-bold text-center small my-4">E-commerce & Logistics</h5>
                                <ul className="text-muted small">
                                    <li>official website for a brand, supporting immersive shopping experiences, flash sales, new product releases and other scenes like Live streaming shopping</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SectionContestTheme;
