const Footer = () => {
    return (
        <footer>
            <div className="text-center" style={{ background: '#FFF8F3' }}>
                <div className="container py-3 px-5">
                    <p className="fw-bolder mb-0">&copy; 2022 - Codepolitan. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
